import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/global/Layout';

import { WpPage_Page_FlexibleContent } from '~/_generated/types';

import { Button, ButtonType, ColorType } from '~/components/elements/buttons/Button';
import { SeoContainer } from '~/components/global/SeoContainer';

export default function fourOhFour({
  data: { wpPage, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const { template } = wpPage;
  return (
    <Layout wp={wp}>
      <SeoContainer post={wpPage} />
      <div className="p-2 md:px-5 md:py-4 mx-auto">
        <div className="purple-gradient">
          <div className="relative flex items-center justify-center pt-32 pb-16 md:pt-72 md:pb-44 noisy">
            <div className="relative px-4 md:px-16 lg:px-24">
              <div className="space-y-[52px] max-w-[720px] text-center">
                <div className="space-y-6">
                  <h1 className="text-h1">{template?.template404?.title}</h1>
                  <div className="p-large" dangerouslySetInnerHTML={{ __html: template?.template404?.description }} />
                </div>
                <div className="flex justify-center">
                  <Button link={template?.template404?.link} type={ButtonType.Button} color={ColorType.White} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query FourOhFourPage {
    wpPage(id: { eq: "cG9zdDoyMTc=" }) {
      ...SEO
      ...GeneratedWpPage
      template {
        ... on WpTemplate_404 {
          templateName
          template404 {
            title
            description
            link {
              url
              title
            }
          }
        }
      }
    }
    wp {
      ...GeneratedWp
    }
  }
`;
